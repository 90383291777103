<template>
  <UiPageLoadingIndicator />
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>

<script setup lang="ts">
// add additional favicons to the head
useHead(favicons)

const route = useRoute()
// don't index campaign pages
if (route.path.match(/\/communities\/.*\/campaign\//)) {
  useRobotsRule(false)
}
</script>
